import { OrderSummary } from '@eo-storefronts/eo-core'
import { Box, BoxProps } from '@mui/material'
import OrderLocation from '~/src/components/orders/OrderLocation'
import OrderReference from '~/src/components/orders/OrderReference'
import OrderDate from '~/src/components/orders/OrderDate'
import OrderTotal from '~/src/components/orders/OrderTotal'
import OrderComments from '~/src/components/orders/OrderComments'
import OrderProductDetails from '~/src/components/orders/OrderProductDetails'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import OrderOrderingMethodResume from '~/src/components/orders/OrderOrderingMethodResume'

interface Props extends BoxProps<'div'> {
  orderSummary: OrderSummary,
}

const OrderInformations = ({ orderSummary, sx = {}, ...boxProps }: Props) => {
  const theme = useCustomMuiTheme()

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateAreas: '' +
          '"order-reference order-date order-product-details"' +
          '"deliver-to order-total order-product-details"' +
          '"order-location . order-product-details"' +
          '"order-comments order-comments order-product-details"',
        gridTemplateColumns: '1fr 1fr 3fr',
        columnGap: 2,
        [theme.breakpoints.down('md')]: {
          gridTemplateAreas: '' +
            '"order-reference"' +
            '"order-date"' +
            '"deliver-to"' +
            '"order-product-details"' +
            '"order-total"' +
            '"order-location"' +
            '"order-comments"',
          gridTemplateColumns: '1fr',
          rowGap: 2
        },
        ...sx
      }}
      {...boxProps}
    >
      <OrderReference
        gridArea='order-reference'
        orderSummary={orderSummary} />
      <OrderLocation
        gridArea='order-location'
        orderSummary={orderSummary} />
      <OrderDate
        gridArea='order-date'
        orderSummary={orderSummary} />
      <OrderProductDetails
        gridArea='order-product-details'
        orderSummary={orderSummary} />
      <OrderOrderingMethodResume
        gridArea='deliver-to'
        orderSummary={orderSummary} />
      <OrderTotal
        gridArea='order-total'
        orderSummary={orderSummary} />
      <OrderComments
        gridArea='order-comments'
        orderSummary={orderSummary} />
    </Box>
  )
}

export default OrderInformations
