import { OrderSummary } from '@eo-storefronts/eo-core'
import Button from '~/src/components/mui-wrappers/buttons/Button'
import { useTranslations } from '~/src/hooks/useTranslations'

interface Props {
  orderSummary: OrderSummary,
}

const OrderGetInvoiceButton = ({ orderSummary }: Props) => {
  const { t } = useTranslations()

  const handleClick = () => {
    if (!orderSummary.invoiceUrl) {
      return
    }

    window.open(orderSummary.invoiceUrl, '_blank', 'noopener,noreferrer')
  }

  if (!orderSummary.invoiceUrl) {
    return null
  }

  return (
    <Button
      sx={{
        p: '1em 5em'
      }}
      variant='contained'
      color='secondary'
      onClick={handleClick}
    >
      {t('orders.getInvoice')}
    </Button>
  )
}

export default OrderGetInvoiceButton
