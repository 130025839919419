import { OrderDetail } from '@eo-storefronts/eo-core'
import { Typography } from '@mui/material'
import { useTranslations } from '~/src/hooks/useTranslations'

interface Props {
  order: OrderDetail,
}

const OrderProductListItem = ({ order }: Props) => {
  const { t } = useTranslations()

  return (
    <Typography variant='body1'>
      {order.quantity}x <strong>{order.name}</strong>
      {order.isCoupon && <span> ({t('shoppingCart.couponDiscount')})</span>}
      {order.isLoyalty && <span> ({t('shoppingCart.loyaltyDiscount')})</span>}
    </Typography>
  )
}

export default OrderProductListItem
