import { OrderSummary } from '@eo-storefronts/eo-core'
import { Box } from '@mui/material'
import FirmInfoCard from '~/src/components/firms/FirmInfoCard'
import Title from '~/src/components/mui-wrappers/Title'
import NoOrderFound from '~/src/components/orders/NoOrderFound'
import OrderAgainButton from '~/src/components/orders/order-again-button'
import OrderDetailActions from '~/src/components/orders/OrderDetailActions'
import OrderInformations from '~/src/components/orders/OrderInformations'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { useTranslations } from '~/src/hooks/useTranslations'

interface Props {
  orderSummary: OrderSummary | null | undefined,
}

const OrderDetailContainer = ({ orderSummary }: Props) => {
  const theme = useCustomMuiTheme()
  const { t } = useTranslations()

  if (!orderSummary) {
    return <NoOrderFound />
  }

  return (
    <Box sx={{
      display: 'grid',
      gridTemplateAreas: '' +
        '"order-info-title order-again-button shop-info-title"' +
        '"order-info order-info shop-info"' +
        '"order-actions order-actions shop-info"',
      columnGap: 2,
      rowGap: 3,
      gridTemplateColumns: '2fr 1fr minmax(max-content, 1fr)',
      [theme.breakpoints.between('md', 'lg')]: {
        gridTemplateAreas: '' +
          '"order-info-title order-again-button"' +
          '"order-info shop-info-title"' +
          '"order-info shop-info"' +
          '"order-actions shop-info"',
        gridTemplateColumns: '1fr 1fr'
      },
      [theme.breakpoints.between('sm', 'md')]: {
        gridTemplateAreas: '' +
          '"order-info-title order-info-title"' +
          '"order-info order-info"' +
          '"order-actions order-again-button"',
        gridTemplateColumns: '1fr 1fr',
        gridTemplateRows: 'max-content max-content max-content'
      },
      [theme.breakpoints.down('sm')]: {
        gridTemplateAreas: '' +
          '"order-info-title"' +
          '"order-info"' +
          '"order-actions"' +
          '"order-again-button"',
        gridTemplateColumns: '1fr'
      }
    }}>
      <Title
        variant='h4'
        sx={{
          gridArea: 'order-info-title'
        }}>
        {t('orders.orderInformations')}
      </Title>

      <OrderAgainButton
        gridArea='order-again-button'
        sx={{
          textAlign: 'right'
        }}
        orderSummary={orderSummary}
      />

      <OrderInformations
        gridArea='order-info'
        orderSummary={orderSummary} />

      <Box
        gridArea='order-actions'
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 2,
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column'
          }
        }}
      >
        <OrderDetailActions orderSummary={orderSummary} />
      </Box>

      <Title
        variant='h4'
        sx={{
          gridArea: 'shop-info-title',
          [theme.breakpoints.down('md')]: {
            display: 'none'
          }
        }}>
        {t('orders.shopInformations')}
      </Title>

      <Box
        gridArea='shop-info'
        sx={{
          [theme.breakpoints.down('md')]: {
            display: 'none'
          }
        }}
      >
        <FirmInfoCard firmId={orderSummary.shopId} />
      </Box>
    </Box>
  )
}

export default OrderDetailContainer
