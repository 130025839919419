import { OrderSummary } from '@eo-storefronts/eo-core'
import { Box, BoxProps, Typography } from '@mui/material'
import DateLabel from '~/src/components/DateLabel'
import { useTranslations } from '~/src/hooks/useTranslations'

interface Props extends BoxProps<'div'> {
  orderSummary: OrderSummary,
}

const OrderDate = ({ orderSummary, ...boxProps }: Props) => {
  const { t } = useTranslations()

  return (
    <Box {...boxProps}>
      <Typography variant='body1'>
        {t('orders.orderDate')}
      </Typography>
      <DateLabel
        variant='h6'
        date={new Date(orderSummary.processTimestamp)}
      />
    </Box>
  )
}

export default OrderDate
