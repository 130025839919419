import { Link, Typography } from '@mui/material'
import { useTranslations } from '~/src/hooks/useTranslations'
import { Firm } from '@eo-storefronts/eo-core'

interface Props {
  firm: Firm,
}

const FirmContact = ({ firm }: Props) => {
  const { t } = useTranslations()

  if (firm?.settings?.theme?.hideEmail || !firm?.email) {
    return null
  }

  return (
    <Typography variant='body1'>
      <strong>{`${t('global.contact')} : `}</strong>
      <Link
        underline='none'
        href={`mailto:${firm.email}`}
        color='inherit'
      >
        <span>{firm.email}</span>
      </Link>
    </Typography>
  )
}

export default FirmContact
