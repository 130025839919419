import { Firm, Id } from '@eo-storefronts/eo-core'
import { Paper, Typography } from '@mui/material'
import Title from '~/src/components/mui-wrappers/Title'
import FirmLocationMap from '~/src/components/firms/FirmLocationMap'
import FirmContact from '~/src/components/firms/FirmContact'
import { useEoValue } from '~/src/hooks/useEoState'
import { FIRM_BY_ID_SELECTOR } from '~/src/stores/firm'
import { LANGUAGE_STATE } from '~/src/stores/lang'

interface Props {
  firmId: Id,
}

const FirmInfoCard = ({ firmId }: Props) => {
  const language = useEoValue(LANGUAGE_STATE)
  const firm = useEoValue<Firm|null>(FIRM_BY_ID_SELECTOR(firmId))

  if (!firm) {
    return null
  }

  return (
    <Paper
      elevation={0}
      sx={{
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        bgcolor: 'secondary.main',
        color: 'secondary.contrastText',
        borderRadius: '1em'
      }}>
      <Title variant='h6'>
        {firm.name[language]}
      </Title>
      {(firm?.address?.street && firm?.address?.zipcode && firm?.address?.locality) && (
        <Typography variant='body1'>
          {`${firm?.address.street} ${firm?.address.zipcode} ${firm?.address.locality}`}
        </Typography>
      )}
      <FirmLocationMap firm={firm}/>
      <FirmContact firm={firm} />
    </Paper>
  )
}

export default FirmInfoCard
